import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const HannahHerbst = () => (
    <Layout title="Alumni Spotlight - Hannah Herbst" className="alumni-center bios">
    {/* Alumni Profile */}
    <Section className="alumni">
        <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Hannah Herbst</li>
        </ul>
        <Row>
        <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Hannah Herbst</h1>
            <h3 className="alumni__profile-type">
                2015 3M Young Scientist Challenge Winner
            </h3>
            <h4 className="alumni__profile-project">
              After the 3M Young Scientist Challenge, Hannah continued to work on her project, BEACON, an ocean energy collection and conversion device. Hannah attended Florida Atlantic University and Stanford University and is now an entrepreneur working on some incredible projects!
            </h4>
            <h2 className="alumni__profile-question">
                Remind us about your 3M Young Scientist Challenge Innovation Project? Did you continue to work on your innovation after you presented it during the Final Event at 3M? 
            </h2>
            <p>
                For my final project, I developed BEACON, an ocean energy collection and conversion device. I was inspired to develop it after learning about energy poverty in middle school and the impact that it has on people’s ability to access medical care, sanitation, and even clean water. This resolve was further strengthened by my pen pal Ruth, who personally experienced the hardships brought on by energy poverty. I continued to work on the innovation through high school, it was a wonderful learning experience. 
            </p>
            <h2 className="alumni__profile-question">
                What is your favorite memory from the 3M Young Scientist Challenge?
            </h2>
            <p>
                My favorite memory from the Young Scientist Challenge was meeting the other finalists, learning about their projects, and going through the week of challenges with them. I also enjoyed meeting my mentor, Mr. Jeff Emslander after collaborating on the project all summer! Visiting 3M’s innovation center where “Science is Applied to Life” was also incredible and inspires me as an inventor today.
            </p>
            <h2 className="alumni__profile-question">
              When you participated in the Challenge you aspired to become a mechanical engineer, is that the pathway that you ended up taking? Tell us more about your journey.
            </h2>
            <p>
              Though I didn't pursue a career as a mechanical engineer, my path led me to become an entrepreneur, where I find immense joy in tackling challenges within the medical field and inventing devices. I continued to develop new technologies through high school and college, and participated in science fairs and undergraduate research with projects involving engineering, biomechanics, and biology. In 2019, I graduated from high school, and in 2020 I graduated from FAU with a B.S. in IT and did a semester at Stanford University. Now at GHM I serve as cofounder and CEO, and am learning many different aspects of running a medical device business, most importantly making something that our customers want and need. 
            </p>
            <h2 className="alumni__profile-question">
              You've recently been working on a project with another former 3M Young Scientist Challenge finalist, Devin Willis. Can you tell us more about that? What has it been like working with a fellow 3M YSC Alumni?
            </h2>
            <p>
              Devin and I attended school and worked on robotics teams together through middle and high school. Last year we cofounded GHM to revolutionize the delivery of emergency medical care by developing uncomplicated, automated devices that drastically reduce the time required for patients to receive critical treatment. We are thrilled to launch our first device this coming winter!
            </p>
            <h2 className="alumni__profile-question">
              How has the 3M Young Scientist Challenge inspired or led you to the work that you’re doing today?
            </h2>
            <p>
              The 3M YSC laid the foundation for where I am today. The challenge showed me that it is not only important to develop great technologies, it is equally important to communicate them well and to collaborate with those around you. Further, the 3M YSC connected me with brilliant fellow finalists who are doing incredible work, and made me feel supported and surrounded for all of these years. Some of my closest friends have done the Young Scientist Challenge and it is amazing to have this community of innovators! I will always be grateful to 3M and Discovery Education for their encouragement of and dedication to young scientists. 
                        </p>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Herbst-Hannah-2024-Alumni-Quote.png" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “I will always be grateful to 3M and Discovery Education for their encouragement of and dedication to young scientists.”
                </h4>
                <h3>Hannah Herbst</h3>
                <p>2015 Finalist</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
                <Row>
                    <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Crouchley-Caroline-2024-Alumni-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2019 Finalist</span>
                <h3>Caroline Crouchley</h3>
                <p>
                  Since participating in the 2019 Challenge, Caroline has been researching to investigate new drip irrigation methods to grow plants more efficiently and conserve water. Caroline is excited to be part of the Alumni Network!
                </p>
                <Link to="caroline-crouchley" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
                    <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Willis-Devin-2024-Alumni-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2017 Finalist</span>
                <h3>Devin Willis</h3>
                <p>
                  Devin’s 3M Young Scientist Challenge project was a device, SlideMap, designed to expedite and enhance the precision of cancer diagnosis. He is currently studying electrical engineering at the University of Florida.
                </p>
                <Link to="devin-willis" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Katie-Lampo-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2017 Finalist</span>
                <h3>Kathryn Lampo</h3>
                <p>
                  Kathryn is currently a sophomore at Columbia University
                  pursuing a B.S. in Mechanical Engineering with a minor in
                  Applied Physics. She’s also interested in aerospace
                  engineering!
                </p>
                <Link to="../katie-lampo" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>

        </Row>
      </Container>
    </Section>
  </Layout>
);

export default HannahHerbst;
